import React from 'react';
import cx from 'classnames';
import styles from './StickyPanel.module.scss';
import BurgerMenu from '../Menu/BurgerMenu';

class StickyPanel extends React.Component {
  render() {
    const {
      base,
      top,
      css,
      isMenuVisible,
      menuClick,
      light,
      style,
      justLogo,
      noOthers,
    } = this.props;

    if (!css) {
      let position = style.position === 'fixed' ? 'absolute' : 'static';
      if (!justLogo) {
        position = 'absolute';
      }

      return (
        <div
          className={styles.wrapper}
          style={
            top
              ? { top: -(top + base) + 'px', position }
              : { top: '0px', position }
          }
        >
          {justLogo && (
            <div
              className={cx(styles.logo, light ? styles.light : styles.dark)}
            />
          )}
          {noOthers && (
            <>
              <a
                className={styles.tiltedEmailTextWhite}
                href="mailto: hello@fablefaber.com"
              >
                hello@fablefaber.com
              </a>

              <BurgerMenu
                active={isMenuVisible}
                onClick={menuClick}
                style={styles.burgerAbs}
              />
            </>
          )}
        </div>
      );
    }

    return (
      <div className={styles.wrapper}>
        <div
          className={cx(
            styles.logoFloat,
            light ? styles.light : styles.dark,
            styles.css
          )}
        />

        <a
          className={styles.tiltedEmailText}
          href="mailto: hello@fablefaber.com"
        >
          hello@fablefaber.com
        </a>

        <BurgerMenu
          asCSS={css}
          active={isMenuVisible}
          onClick={menuClick}
          style={styles.burgerFixed}
        />
      </div>
    );
  }
}

export default StickyPanel;
