import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { Fade } from 'react-reveal';
import { Sticky } from 'react-sticky';
import { timings } from '../../contants';
import DownAnchor from '../DownAnchor/DownAnchor';
import StickyPanel from '../StickyPanel/StickyPanel';
import styles from './Featured.module.scss';

function Featured(props) {
  const sectionElement = useRef(null);
  const [distance, setDistance] = useState(0);

  setTimeout(() => {
    sectionElement &&
      sectionElement.current &&
      setDistance(sectionElement.current.offsetTop);
  }, 1);

  return (
    <section
      ref={sectionElement}
      className={styles.featured}
      id="featured-anchor"
    >
      <Sticky topOffset={distance}>
        {({ distanceFromTop, style }) => (
          <StickyPanel
            style={style}
            light
            noOthers
            isMenuVisible={props.isMenuVisible}
            menuClick={props.menuClick}
            base={distance}
            top={distanceFromTop}
          />
        )}
      </Sticky>
      <Sticky topOffset={distance}>
        {({ distanceFromTop, style }) => (
          <StickyPanel
            justLogo
            style={style}
            light
            isMenuVisible={props.isMenuVisible}
            menuClick={props.menuClick}
            base={distance}
            top={distanceFromTop}
          />
        )}
      </Sticky>
      <Container fluid>
        <Row>
          <Col md={12} lg={10} style={{ minHeight: '80vh' }}>
            <Fade delay={200} duration={timings.fade}>
              <div className={styles.featuredContent}>
                <p>
                  We are a <b>boutique creative agency</b> that believes in the
                  power of <wbr />
                  exceptional, holistic design to create{' '}
                  <b>real-world outcomes</b> for
                  <wbr /> forward-thinking brands.
                </p>
                <p>
                  How we do that is all in the name –{' '}
                  <div className={styles.logo} /> ; We help you shape your
                  <wbr /> narrative and tell your story, bringing it to life and
                  creating the <wbr />
                  experience people have with your brand.
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
      <div
        className="down-anchor"
        style={{
          position: 'absolute',
          bottom: '10%',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <DownAnchor light to="works-anchor" />
      </div>
    </section>
  );
}

export default Featured;
