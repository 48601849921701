import cx from 'classnames';
import React from 'react';
import { Visible } from 'react-grid-system';
import { Fade } from 'react-reveal';
import { timings } from '../../contants';
import DownAnchor from '../DownAnchor/DownAnchor';
import styles from './Talent.module.scss';

const columns = [
  {
    title: `Insight.`,
    markup: `<p>We get to know you and your customers, the main challenges and what outcomes you’re trying to achieve.</p>`,
  },
  {
    title: `Strategy.`,
    markup:
      '<p>Bridging the gap between business and design, we tailor our outputs and activities to your individual goals and circumstances. No one size fits all.</p>',
  },
  {
    title: 'Delivery.',
    markup:
      '<p>We do what we do best. From branding to web and product design, we create outputs that move the dial, giving you the results you need.</p>',
  },
];

const Talent = props => {
  return (
    <section className={styles.talent} id="talent-anchor">
      <h2>Approach</h2>
      <Fade delay={250} duration={timings.talent}>
        <div className={styles.columns}>
          {columns.map((column, index) => (
            <React.Fragment key={index}>
              <div className={styles.column}>
                <div className={styles.lineWrapper}>
                  <h4 dangerouslySetInnerHTML={{ __html: column.title }} />
                </div>
                <div
                  className={cx(styles.talentDescription, {
                    [styles.talentDescriptionLast]:
                      index === columns.length - 1,
                  })}
                >
                  <div dangerouslySetInnerHTML={{ __html: column.markup }} />
                  <Visible xs sm>
                    <div className={styles.line} />
                  </Visible>
                </div>
              </div>
              {index !== columns.length - 1 && (
                <Visible md lg xl>
                  <div className={styles.line} />
                </Visible>
              )}
              {index === columns.length - 1 && (
                <Visible md lg xl>
                  <div className={styles.lineLast} />
                </Visible>
              )}
            </React.Fragment>
          ))}
        </div>
      </Fade>
      <div
        style={{
          position: 'absolute',
          bottom: '25px',
          height: '40px',
          width: '40px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <DownAnchor className={styles.arrow} to="contact-anchor" />
      </div>
    </section>
  );
};

export default Talent;
