import React from 'react';
import { Fade } from 'react-reveal';
import { timings } from '../../contants';
import DownAnchor from '../DownAnchor/DownAnchor';
import styles from './Story.module.scss';

const columns = [
  {
    title: `Let's start with the opportunity`,
    markup: `<p>Bring us your challenges and aspirations, and we’ll work together to make them a reality.</p>`,
  },
  {
    title: `We’ll be with you <br /> all the way`,
    markup:
      '<p>Design is not a linear step-by-step process. It’s a way of thinking. It’s solving real problems for real people in real time.</p><p>You can be involved in the process as much or as little as you want</p>',
  },
  {
    title: 'Small but mighty and all about results',
    markup:
      '<p>We’re a small group of hungry, talented professionals. You will get our best researchers, designers and consultants.</p><p>We live for achieving real results and delivering the absolute best in class.</p>',
  },
];

const Story = props => {
  return (
    <section className={styles.talent} id="story-anchor">
      <h2>Our story</h2>
      <Fade delay={250} duration={timings.talent}>
        <p>
          Formed by an <b>experienced creative team</b> with distinct and
          complementary <b>expertise</b> across design disciplines and
          industries, we wanted to be able to offer the best of both worlds –
          the collaboration of an <b>agency</b> and the depth of a{' '}
          <b>specialist</b>.
        </p>
      </Fade>
      <div
        style={{
          position: 'absolute',
          bottom: '25px',
          height: '40px',
          width: '40px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <DownAnchor className={styles.arrow} to="talent-anchor" />
      </div>
    </section>
  );
};

export default Story;
