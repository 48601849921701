import React from 'react';
import DownAnchor from '../DownAnchor/DownAnchor';
import styles from './Banner.module.scss';

const Banner = () => (
  <section className={styles.banner} id="banner-anchor">
    <div className={styles.imageWrapper}>
      <div className={styles.bannerLogo} />
      {/* <div className={styles.aside}>
        <h3>Small agency big ideas.</h3>
      </div> */}
    </div>
    <div
      className="down-anchor"
      style={{
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      <DownAnchor delay={500} to="featured-anchor" />
    </div>
  </section>
);

export default Banner;
