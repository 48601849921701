import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { setConfiguration } from 'react-grid-system';
import Helmet from 'react-helmet';
import Banner from '../components/Banner/Banner';
import Contact from '../components/Contact/Contact';
import Featured from '../components/Featured/Featured';
import StickyPanel from '../components/StickyPanel/StickyPanel';
import Story from '../components/Story/Story';
import Talent from '../components/Talent/Talent';
import Works from '../components/Works/Works';
import Layout from './../components/Layout/Layout';

setConfiguration({ containerWidths: [476, 668, 924, 1100] });

class HomeIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMenuVisible: false };
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
  }

  handleToggleMenu() {
    this.setState({
      isMenuVisible: !this.state.isMenuVisible,
    });
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query BannerQuery {
            image: file(relativePath: { eq: "img/banner.jpg" }) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width: 651) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        `}
        render={data => {
          const { isMenuVisible } = this.state;

          return (
            <Layout
              isMenuVisible={isMenuVisible}
              menuClick={this.handleToggleMenu}
            >
              <Helmet title="Fable & Faber" />
              <StickyPanel
                css
                isMenuVisible={isMenuVisible}
                menuClick={this.handleToggleMenu}
              />
              <Banner data={data} />
              <Featured
                isMenuVisible={isMenuVisible}
                menuClick={this.handleToggleMenu}
              />
              <Works />
              <Story />
              <Talent />
              <Contact
                isMenuVisible={isMenuVisible}
                menuClick={this.handleToggleMenu}
              />
            </Layout>
          );
        }}
      />
    );
  }
}

export default HomeIndex;
