import cx from 'classnames';
import { graphql, Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Container, Row, Visible } from 'react-grid-system';
import { Fade } from 'react-reveal';
import { timings } from '../../contants';
import DownAnchor from '../DownAnchor/DownAnchor';
import styles from './Works.module.scss';

const ImageWrapper = ({ client_name, slogan, srcSet, className, index }) => {
  return (
    <Fade duration={timings.works} delay={200}>
      <div className={cx(styles.imagePanel, className)}>
        <Visible xs sm>
          <div className={styles.mobileWork}>
            <span>{client_name}</span>
            <span>{slogan}</span>
          </div>
        </Visible>
        <div className={styles.imagePanelOverlay}>
          <div className={styles.imagePanelOverlayText}>
            <div className={styles.slogan}>{slogan}</div>
            <div className={styles.title}>{client_name}</div>
          </div>
        </div>
        <Img
          className={styles.workImage}
          fluid={{
            ...srcSet,
            aspectRatio: isMobile ? 1 / 1 : srcSet.aspectRatio,
          }}
          quality={100}
        />
      </div>
    </Fade>
  );
};

const Works = () => {
  return (
    <StaticQuery
      query={graphql`
        query thumbnailQuery {
          allMarkdownRemark(filter: { frontmatter: { type: { eq: "post" } } }) {
            edges {
              node {
                frontmatter {
                  title
                  path
                  category
                  client_name
                  slogan
                  title
                  works_index
                  work_tile {
                    childImageSharp {
                      fluid(maxWidth: 500, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const panelData = data.allMarkdownRemark.edges
          .map(
            (
              {
                node: {
                  frontmatter: {
                    work_tile,
                    path,
                    client_name,
                    category,
                    slogan,
                    title,
                    works_index,
                  },
                },
              },
              index
            ) => ({
              srcSet: work_tile.childImageSharp.fluid,
              client_name,
              path,
              category,
              slogan,
              title,
              works_index,
              index,
            })
          )
          .sort((a, b) => a.works_index - b.works_index);

        const [one, two, three, four, five, six] = panelData;

        return (
          <section className={cx(styles.works, 'element')} id="works-anchor">
            <Container fluid>
              <Row>
                <Col md={4}>
                  <div className={styles.spacer}>
                    <h2>Our work</h2>
                  </div>
                </Col>
              </Row>
              <Row nogutter justify="center">
                <Col>
                  <div className={styles.worksCascade}>
                    <div
                      className={cx(styles.worksColumn, styles.worksColumnOne)}
                    >
                      <Link to={one.path}>
                        <ImageWrapper className={styles.imgOne} {...one} />
                      </Link>
                      <Link to={two.path}>
                        <ImageWrapper className={styles.imgTwo} {...two} />
                      </Link>
                    </div>
                    <div
                      className={cx(styles.worksColumn, styles.worksColumnTwo)}
                    >
                      <Link to={three.path}>
                        <ImageWrapper className={styles.imgthree} {...three} />
                      </Link>
                      <Link to={four.path}>
                        <ImageWrapper className={styles.imgfour} {...four} />
                      </Link>
                    </div>
                    <div
                      className={cx(
                        styles.worksColumn,
                        styles.worksColumnThree
                      )}
                    >
                      <Link to={five.path}>
                        <ImageWrapper className={styles.imgfive} {...five} />
                      </Link>
                      <Link to={six.path}>
                        <ImageWrapper className={styles.imgsix} {...six} />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <div
              className="down-anchor"
              style={{
                position: 'absolute',
                bottom: '25px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <DownAnchor to="story-anchor" />
            </div>
          </section>
        );
      }}
    />
  );
};

export default Works;
