import cx from 'classnames';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Col, Container, Row, Visible } from 'react-grid-system';
import ReactResizeDetector from 'react-resize-detector';
import { Fade } from 'react-reveal';
import { Sticky } from 'react-sticky';
import { timings } from '../../contants';
import StickyPanel from '../StickyPanel/StickyPanel';
import styles from './Contact.module.scss';

function Contact(props) {
  const sectionElement = useRef(null);
  const [distance, setDistance] = useState(0);

  useLayoutEffect(() => {
    sectionElement &&
      sectionElement.current &&
      setDistance(sectionElement.current.offsetTop);
  }, 1);

  const handleResize = () => {
    sectionElement &&
      sectionElement.current &&
      setDistance(sectionElement.current.offsetTop);
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          image_one: file(relativePath: { eq: "london-contact.jpg" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image_two: file(relativePath: { eq: "melbourne-contact.jpg" }) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <section
            ref={sectionElement}
            className={styles.contact}
            id="contact-anchor"
          >
            <ReactResizeDetector
              handleWidth
              handleHeight
              onResize={handleResize}
            />
            <Sticky>
              {({ distanceFromTop, style }) => (
                <StickyPanel
                  style={style}
                  justLogo
                  noOthers
                  isMenuVisible={props.isMenuVisible}
                  menuClick={props.menuClick}
                  light
                  base={distance}
                  top={distanceFromTop}
                />
              )}
            </Sticky>

            <Fade delay={250} duration={timings.talent}>
              <Container fluid>
                <Row>
                  <Col className={styles.heading}>
                    <h2>Talk to us</h2>
                    <p className={cx('featured', styles.featured)}>
                      We're based in London and Melbourne. <br />
                      Drop us a line, and let's talk more.
                    </p>
                  </Col>
                </Row>

                <Row justify="between">
                  <Col sm={12} md={6} className={styles.row}>
                    <Row nogutter justify="start">
                      <Col xs={12} sm={5} className={styles.content}>
                        <h4 className={styles.contentTitle}>London</h4>
                        <h5 className={styles.contentSubTitle}>Email</h5>
                        <a
                          className="link--light"
                          href="mailto: london@fablefaber.com"
                        >
                          london@fablefaber.com
                        </a>
                        <h5 className={styles.contentSubTitle}>Phone</h5>
                        <a className="link--light" href="tel:+020 365 28618">
                          020 365 28618
                        </a>
                        <a
                          href="https://goo.gl/maps/YKFpJsFBppizp1Wr7"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link--light"
                        >
                          <h5 className={styles.contentSubTitle}>Address</h5>
                          <p>1 Mark Square</p>
                          <p>Shoreditch</p>
                          <p>London</p>
                          <p>EC2A 4EG</p>
                        </a>
                      </Col>
                      <Visible xl lg md>
                        <Col xs={7}>
                          <Img
                            quality={100}
                            className={styles.contactPanelImage}
                            fluid={data.image_one.childImageSharp.fluid}
                          />
                        </Col>
                      </Visible>
                    </Row>
                  </Col>

                  <Col sm={12} md={6}>
                    <Row nogutter justify="end">
                      <Col xs={12} sm={5} className={styles.content}>
                        <h4 className={styles.contentTitle}>Melbourne</h4>
                        <h5 className={styles.contentSubTitle}>Email</h5>
                        <a
                          className="link--light"
                          href="mailto: melbourne@fablefaber.com"
                        >
                          melbourne@fablefaber.com
                        </a>
                        <h5 className={styles.contentSubTitle}>Phone</h5>
                        <a className="link--light" href="tel:+1800 717 485">
                          1800 717 485
                        </a>
                        <h5 className={styles.contentSubTitle}>Address</h5>
                        <a
                          href="https://goo.gl/maps/6AihMkfQ5Kj2ThES7"
                          target="_blank"
                          className="link--light"
                        >
                          <p>105 Wellington St</p>
                          <p>St Kilda</p>
                          <p>Melbourne</p>
                          <p>VIC 3182</p>
                        </a>
                      </Col>
                      <Visible xl lg md>
                        <Col xs={7}>
                          <Img
                            quality={100}
                            className={styles.contactPanelImage}
                            fluid={data.image_two.childImageSharp.fluid}
                          />
                        </Col>
                      </Visible>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Fade>
          </section>
        );
      }}
    />
  );
}

export default Contact;
